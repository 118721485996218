import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { LayoutService } from './service/app.layout.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {
    showChangePasswordDialog: boolean = false;

    model: any[] = [];

    constructor(
        public layoutService: LayoutService,
        private authService: AuthService
    ) { }

    ngOnInit() {
        this.model = [
            {
                label: 'Produse',
                items: [
                    { label: 'Lista produse', icon: 'pi pi-fw pi-list', routerLink: [''] },
                    // { label: 'Oferte', icon: 'pi pi-fw pi-star-fill', routerLink: ['/offers'] },
                ]
            },
            {
                label: 'Comenzi',
                items: [
                    { label: 'Istoric comenzi', icon: 'pi pi-fw pi-history', routerLink: ['/orders'] },
                ]
            },
            {
                label: 'Cont',
                items: [
                    { label: 'Schimba parola', icon: 'pi pi-fw pi-lock', command: () => this.layoutService.emitChangePasswordEvent(true) },
                    { label: 'Deconectare', icon: 'pi pi-fw pi-sign-out', command: () => this.authService.logout() }
                ]
            }
        ];
    }
}
