<div class="layout-wrapper" [ngClass]="containerClass">
    <app-topbar></app-topbar>
    <div class="layout-sidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <router-outlet></router-outlet>
            
            <p-dialog [(visible)]="showChangePasswordDialog" (onHide)="setChangePassToFalse()" [style]="{width: '450px'}" header="Schimbă parola" [modal]="true" class="p-fluid" >
                <app-change-password (closeEvent)="onCloseChangePassword($event)" [showPopin]="showChangePasswordDialog"></app-change-password>
            </p-dialog>
        </div>
        <app-footer></app-footer>
    </div>
    <div class="layout-mask"></div>
</div>
