import { Product } from "./Product";

export class Cart {
    id!: number;
    cartItems: CartItem[] = [];
}

export class CartItem {
    id?: number;
    product!: Product;
    quantity!: number;
    isCash!: boolean;
}

export class CartItemDTO {
    product!: number;
    cart!: number;
    quantity!: number;
    isCash!: boolean;
}