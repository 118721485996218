import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { AppLayoutModule } from './layout/app.layout.module';
import { AuthService } from './auth/auth.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthGuard } from './auth/auth.guard';
import { APP_PRIMENG_MODULE } from './shared/APP.MODULE-PRIMENG';
import { registerLocaleData } from '@angular/common';
import localeRo from '@angular/common/locales/ro';
import { MessageService } from 'primeng/api';
registerLocaleData(localeRo);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    AppLayoutModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    APP_PRIMENG_MODULE
  ],
  providers: [
    AuthService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    MessageService,
    { provide: LOCALE_ID, useValue: 'ro-RO'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
