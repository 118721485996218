import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ChangePasswordService } from '../../services/change-password/change-password.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  @Input() showPopin: boolean = false;
  @Output() closeEvent: EventEmitter<any> = new EventEmitter();
  password: any = {};
  isLoadingChangePassword: boolean = false;

  constructor(
    private changePasswordService: ChangePasswordService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["showPopin"] && changes["showPopin"].currentValue) {
      this.password = {};
    }
  }

  savePassword() {
    this.changePasswordService.changePassword(this.password).subscribe(
      _ => {
        this.isLoadingChangePassword = false;
        this.closeEvent.emit();
      },
      err => {
        this.isLoadingChangePassword = false;
        this.closeEvent.emit(err);
      }
    )
  }

  showSaveButton() {
    return this.password.oldPassword 
      && this.password.password 
      && this.password.passwordConfirm 
      && this.password.password == this.password.passwordConfirm
      && this.password.password.length >= 6;
  }

}
