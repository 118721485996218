<div class="layout-topbar">
    <a class="layout-topbar-logo" routerLink="">
        <img src="assets/logo_long.png" alt="logo">
    </a>

    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
        <button *ngIf="(isStaff && badge != '-1') || !isStaff" class="p-link layout-topbar-button" [routerLink]="'cart'">
            <i class="pi pi-shopping-cart" pBadge value="{{badge}}"></i>
        </button>
    </div>
</div>