import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { CartService } from '../modules/views/cart/cart.service';
import { LayoutService } from "./service/app.layout.service";

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit {
    badge!: string;
    items!: MenuItem[];

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

    isStaff: boolean = false;
    
    constructor(
        public layoutService: LayoutService,
        private cartService: CartService
    ) { }

    ngOnInit(): void {
        if (localStorage.getItem('url') == '2' || localStorage.getItem('url') == '3') {
            this.isStaff = true;
        }

        this.cartService.getCart().subscribe(cart => {
            this.badge = cart && cart.id ? cart.cartItems.length.toString() : "-1"
        });

        this.cartService.getCartUpdates().subscribe(cart => {
            this.badge = cart && cart.id ? cart.cartItems.length.toString() : "-1"
        });
    }

}
