import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.includes('login') || request.url.includes('refresh-token')) {
      return next.handle(request);
    }

    if (this.authService.token && !this.authService.tokenExpired()) {
      return next.handle(this.addTokenHeader(request, this.authService.token));
    } else {
      this.authService.logout();
      return throwError(new Error());
    }
  }


  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set('Authorization', 'Bearer '.concat(token)) });
  }
}
