<p-message severity="warn" text="Securitatea contului dvs. este slaba. Va recomandam sa va schimbati parola."></p-message>

<div class="field mt-4">
    <span class="p-float-label p-input-icon-left">
        <i class="pi pi-lock-open"></i>
        <input type="password" pInputText id="oldPassword" [(ngModel)]="password.oldPassword"/>
        <label for="oldPassword">Parola actuală</label>
    </span>
</div>

<div class="field">
    <span class="p-float-label mt-4 p-input-icon-left">
        <i class="pi pi-lock"></i>
        <input type="password" pInputText id="password" [(ngModel)]="password.password"/>
        <label for="password">Parola nouă (minim 6 caractere)</label>
    </span>
</div>

<div class="field">
    <span class="p-float-label mt-4 p-input-icon-left">
        <i class="pi pi-replay"></i>
        <input type="password" pInputText id="passwordConfirm" [(ngModel)]="password.passwordConfirm"/>
        <label for="passwordConfirm">Confirmare parola nouă</label>
    </span>
</div>

<button pButton pRipple label="Salvează" class="col" (click)="savePassword()" [disabled]="!showSaveButton()" [loading]="isLoadingChangePassword"></button>