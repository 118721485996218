import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { FormsModule } from '@angular/forms';
import { APP_PRIMENG_MODULE } from './APP.MODULE-PRIMENG';



@NgModule({
  declarations: [
    ChangePasswordComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    APP_PRIMENG_MODULE
  ],
  exports: [
    ChangePasswordComponent
  ]
})
export class SharedModule { }
