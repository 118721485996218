import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, of, Subject, tap } from 'rxjs';
import { Cart, CartItem, CartItemDTO } from 'src/app/shared/models/Cart';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  cartSubject = new Subject<Cart>();
  cartLoaded: boolean = false;
  private cart!: Cart;
  private cartsUrl: string = environment.apiUrl + "/carts/";
  private cartItemsUrl: string = environment.apiUrl + "/cart-items/";

  constructor(
    private http: HttpClient
  ) { }

  getCart(clientId?: number ) {
    const isStaff = localStorage.getItem('url') == '2' || localStorage.getItem('url') == '3';

    if (this.cartLoaded && !clientId) {
      return of(this.cart);
    } 

    if (!isStaff) {
      return this.http.get(this.cartsUrl + localStorage.getItem('uid') + "/").pipe(
        tap((data: any) => {
          this.cart = data;
          this.cartLoaded = true;
          this.cartSubject.next(this.cart);
        },
        catchError((err: any) => {
          this.cartSubject.next(this.cart);
          return of(null);
        }))
      );
    }

    if (clientId) {
      return this.http.get(this.cartsUrl + clientId + "/").pipe(
        tap((data: any) => {
          this.cart = data;
          this.cartLoaded = true;
          this.cartSubject.next(this.cart);
        })
      );
    }

    return of(null);
  }

  getCartUpdates() {
    return this.cartSubject;
  }

  setCart(cart: Cart) {
    this.cart = cart;
    this.cartSubject.next(this.cart);
  }

  addItemInCart(item: CartItem) {
    this.cart.cartItems.push(item);
    this.cartSubject.next(this.cart);
  }

  deleteCartItem(id: any) {
    return this.http.delete(this.cartItemsUrl + id + "/").pipe(
      tap(_ => {
        this.cart.cartItems = this.cart.cartItems.filter(item => item.id !== id);
        this.cartSubject.next(this.cart);
      })
    );
  }

  createCartItem(item: CartItemDTO) {
    return this.http.post(this.cartItemsUrl, item).pipe(
      tap((cartItem: any) => {
        this.cart.cartItems.push(cartItem);
        this.cartSubject.next(this.cart);
      })
    )
  }

  updateCartItemQty(item: CartItem) {
    return this.http.patch(this.cartItemsUrl + item.id + "/", { quantity: item.quantity, isCash: item.isCash }).pipe(
      tap((cartItem: any) => {
        this.cart.cartItems = [...this.cart.cartItems.filter(_cartItem => _cartItem.id !== cartItem.id), cartItem];
        this.cartSubject.next(this.cart);
      })
    )
  }
  
  resetCart() {
    this.cart = new Cart();
    this.cartSubject.next(this.cart);
  }
}
